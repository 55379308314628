<script>
  import { onMount } from "svelte";
  import { Duration } from "luxon";
  import Portal from "../../common/Portal.svelte";
  import generateApiHeaders from "../../common/generateApiHeaders.js";

  const sessionId = document.cookie.match(/session_id=([^;]*)/)[1];
  const apiHeaders = generateApiHeaders()

  let dialogIsOpen = $state(false);
  let noteIsExpanded = $state(false);
  let secondsLeft = $state(false);

  const toggleNote = () => {
    noteIsExpanded = !noteIsExpanded;
  };

  onMount(() => {

    // Poll for timeout seconds. If zero or false, there is
    // no active hold.
    setInterval(async () => {
      const secondsLeftResponse = await fetch(
        `api/lq_holds?get_timeout_seconds=true&session_id='${sessionId}'`,
        {
          method: "GET",
          headers: apiHeaders,
        },
      );

      const payload = await secondsLeftResponse.json();
      const secondsLeftFromServer = payload.seconds_left;

      if (secondsLeftFromServer === false) {
        secondsLeft = false;
        noteIsExpanded = false;
        dialogIsOpen = false;
        return;
      }

      if (secondsLeftFromServer >= 0) {
        secondsLeft = Duration.fromObject({ seconds: secondsLeftFromServer });
        dialogIsOpen = true;
        return;
      }

      // reload the page, which will set off any need to mark pending
      // and redirect to the timeout page.
      window.location.reload();
    }, 1000);
  });
</script>

<Portal>
  <div class="wrapper">
    <dialog class="dialog" open={dialogIsOpen}>
      {#if noteIsExpanded}
        <div class="note">
          <p>
            You have one or more Limited Quantity items in your cart, which
            means you have this much time to check out. If you don't check out
            in time, your reserved quantity will be released.
            <button class="note-okay-button" on:click={toggleNote}>Okay</button>
          </p>
        </div>
      {:else}
        <div class="title">
          Time To Check Out
          <div on:click={toggleNote} class="note-trigger">?</div>
        </div>

        {#if secondsLeft}
          <div class="time-left">{secondsLeft.toFormat("m:ss")}</div>
        {/if}
      {/if}
    </dialog>
  </div>
</Portal>

<style>
  .wrapper {
    margin: 0;
    padding: 3px;
    position: fixed;
    bottom: 200px;
    right: 20px;
    z-index: 50000;
    width: 300px;
  }

  .dialog {
    background-color: #fff;
    border: 2px solid #fcc017;
    box-shadow: 0 0 0.5rem #333;
    padding: 0;
  }

  .title {
    color: #fcc017;
    font-weight: bold;
    padding: 5px;
  }

  .time-left {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;
    text-align: center;
  }

  .note-trigger {
    border-radius: 15px;
    background: #fcc017;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    padding-bottom: 4px;
    text-align: center;
    width: 20px;
    height: 15px;
  }

  .note {
    padding: 5px;
  }

  .note-okay-button {
    margin-top: 5px;
  }
</style>
