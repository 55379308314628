<script>
  import { onMount, onDestroy } from "svelte";
  let ref = $state();
  let portal = $state();

  let { children, left, top } = $props()

  onMount(() => {
    portal = document.createElement("div");
    portal.style.position = "absolute"
    portal.style.zIndex = 1000000
    portal.style.left = left + "px"
    portal.style.top = top + "px"
    document.body.appendChild(portal);
    portal.appendChild(ref);
  });

  onDestroy(() => {
    document.body.removeChild(portal);
  });
</script>

<div class="portal-clone">
  <div bind:this={ref}>
    {@render children?.()}
  </div>
</div>

<style>
  .portal-clone {
    display: none;
  }
</style>
