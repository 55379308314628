export default () => {

  const apiEmailMatch = document.cookie.match(/api_email=([^;]*)/)
  if (!apiEmailMatch) return false

  const apiKey = document.cookie.match(/api_key=([^;]*)/)
  if (!apiKey) return false

  const apiEmailDecoded = decodeURIComponent(apiEmailMatch[1])
  const authToken = btoa(`${apiEmailDecoded}:${apiKey[1]}`);

  return {
    Authorization: `Basic ${authToken}`,
    "Content-Type": "application/json",
  }
}